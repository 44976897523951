@import url(/assets/fonts/INTERSTATE/style.css);
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-overflow-scrolling: touch;
}

@font-face {
  font-family: "VCROSDMono";
  src: url(/static/media/VCROSD.0d91e770.svg#VCROSDMono) format("svg"),
    url(/static/media/VCROSD.5fcd6c65.ttf) format("truetype"),
    url(/static/media/VCROSD.465d86ff.woff) format("woff"),
    url(/static/media/VCROSD.b66098ec.eot) format("embedded-opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "SFProText-Bold";
  src: url(/static/media/SFProText-Bold.3c2386b2.eot);
  src: url(/static/media/SFProText-Bold.3c2386b2.eot?#iefix) format("embedded-opentype"),
    url(/static/media/SFProText-Bold.0030a8de.svg#SFProText-Bold) format("svg"),
    url(/static/media/SFProText-Bold.d7b1927c.ttf) format("truetype"),
    url(/static/media/SFProText-Bold.2030e8bf.woff) format("woff");
  font-weight: normal;
  font-style: normal;
}

* {
  padding: 0;
  margin: 0;
  outline: none;
  -moz-user-select: none; /* Firefox */
  -webkit-user-select: none; /* Chrome, Safari, Opéra depuis la version 15 */
  -ms-user-select: none; /* Internet explorer depuis la version 10 et Edge */
  user-select: none; /* Propriété standard */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

a.nostyle:link {
  text-decoration: inherit;
  color: inherit;
  cursor: auto;
}

a.nostyle:visited {
  text-decoration: inherit;
  color: inherit;
  cursor: auto;
}

/* .img {
  background-color: rgba(191, 188, 188, 0.3);
} */

.img0 {
  margin-top: 0 !important;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

