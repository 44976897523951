body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-overflow-scrolling: touch;
}

@font-face {
  font-family: "VCROSDMono";
  src: url("./fonts/VCR_OSD/VCROSD.svg#VCROSDMono") format("svg"),
    url("./fonts/VCR_OSD/VCROSD.ttf") format("truetype"),
    url("./fonts/VCR_OSD/VCROSD.woff") format("woff"),
    url("./fonts/VCR_OSD/VCROSD.eot") format("embedded-opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "SFProText-Bold";
  src: url("./fonts/SF/SFProText-Bold.eot");
  src: url("./fonts/SF/SFProText-Bold.eot?#iefix") format("embedded-opentype"),
    url("./fonts/SF/SFProText-Bold.svg#SFProText-Bold") format("svg"),
    url("./fonts/SF/SFProText-Bold.ttf") format("truetype"),
    url("./fonts/SF/SFProText-Bold.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

* {
  padding: 0;
  margin: 0;
  outline: none;
  -moz-user-select: none; /* Firefox */
  -webkit-user-select: none; /* Chrome, Safari, Opéra depuis la version 15 */
  -ms-user-select: none; /* Internet explorer depuis la version 10 et Edge */
  user-select: none; /* Propriété standard */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
